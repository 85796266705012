import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Layout',
		component: () => import('../views/layout.vue'),
		children: [
			{
				path: "",
				name: 'Home',
				component: () => import('../views/home.vue'),
				meta: { title: '首页 - 上海有料广告有限公司' }
			},
			{
				path: '/about',
				name: 'About',
				component: () => import('../views/about.vue'),
				meta: { title: '关于有料 - 上海有料广告有限公司' }
			},
			{
				path: '/service-brand',
				name: 'ServiceBrand',
				component: () => import('../views/serviceBrand.vue'),
				meta: { title: '服务品牌 - 上海有料广告有限公司' }
			},
			{
				path: '/service-case',
				name: 'ServiceCase',
				component: () => import('../views/serviceCase.vue'),
				meta: { title: '服务案例 - 上海有料广告有限公司' },
			},
			{
				path: '/business-insight',
				name: 'BusinessInsight',
				component: () => import('../views/businessInsight.vue'),
				meta: { title: '企业洞察 - 上海有料广告有限公司' }
			},
			{
				path: '/contact-us',
				name: 'ContactUs',
				component: () => import('../views/contactUs.vue'),
				meta: { title: '联系我们 - 上海有料广告有限公司' }
			},
			{
				path: '/team',
				name: 'Team',
				component: () => import('../views/team.vue'),
				meta: { title: '团队优势 - 上海有料广告有限公司' }
			},
			{
				path: "/service-case/case1",
				name: 'Case1',
				component: () => import('../views/case1.vue'),
				meta: { title: '案例 - 上海有料广告有限公司' }
			},
			{
				path: "/service-case/case2",
				name: 'Case2',
				component: () => import('../views/case2.vue'),
				meta: { title: '案例 - 上海有料广告有限公司' }
			},
			{
				path: "/service-case/case3",
				name: 'Case3',
				component: () => import('../views/case3.vue'),
				meta: { title: '案例 - 上海有料广告有限公司' }
			},
			{
				path: "/service-case/case4",
				name: 'Case4',
				component: () => import('../views/case4.vue'),
				meta: { title: '案例 - 上海有料广告有限公司' }
			},
			{
				path: "/service-case/case5",
				name: 'Case5',
				component: () => import('../views/case5.vue'),
				meta: { title: '案例 - 上海有料广告有限公司' }
			},
			{
				path: "/service-case/case6",
				name: 'Case6',
				component: () => import('../views/case6.vue'),
				meta: { title: '案例 - 上海有料广告有限公司' }
			},
			{
				path: "/service-case/case7",
				name: 'Case7',
				component: () => import('../views/case7.vue'),
				meta: { title: '案例 - 上海有料广告有限公司' }
			},
			{
				path: "/service-case/case8",
				name: 'Case8',
				component: () => import('../views/case8.vue'),
				meta: { title: '案例 - 上海有料广告有限公司' }
			},
			{
				path: "/service-case/case9",
				name: 'Case9',
				component: () => import('../views/case9.vue'),
				meta: { title: '案例 - 上海有料广告有限公司' }
			},
		]
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

// 路由前置导航守卫
router.beforeEach((to, from, next) => {
	// 根据路由元信息设置文档标题
	window.document.title = to.meta.title || '上海有料广告有限公司'
	next()
})

export default router
