<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  setup() {

  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html {
  font-size: 0;
}
body {
  background-color: #000;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
